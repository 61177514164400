








































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  SetPromoCodeForPromoRequestPromoCodeTable,
  GetPromoCodeTotalQuotaAmount,
  GetPromoCodeConditionText,
} from "@/helper/promoCodeHelper";
import { namespace } from "vuex-class";
import { NewObject } from "../../../helper/shareHelper";
import { PromoCampaignModel } from "../../../model/promoCode/promoCodeModel";
import { LoadingStatusType } from "../../../enums/enums";

const StorePromoCode = namespace("PromoCode");
@Component({ name: "promoCodeRequestStatusDetail" })
export default class promoCodeRequestStatusDetail extends Vue {
  @Prop({ required: true }) campaignIndex!: number;
  @Prop({ required: true }) campaignLength!: number;
  @Prop({ required: true }) campaignDetail!: Record<string, any>;
  private LoadingStatusType = LoadingStatusType;
  private totalPromoCode = 0;
  private totalQuotaCode = 0;
  private table: Record<string, any> = {
    header: [],
    detail: [],
  };
  private requestedCampaign: PromoCampaignModel | null = null;
  private campaignList: Array<PromoCampaignModel> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private footerProp = {
    "items-per-page-options": this.pageSizeList,
  };

  get promoCodeConditionText() {
    return this.campaignDetail.promoCampaign.conditionList.length > 0
      ? GetPromoCodeConditionText(this.campaignDetail.promoCampaign.conditionList)
      : "-";
  }

  @StorePromoCode.Getter
  private getHeaderPromoRequestStatusPromoCodeTable!: () => Record<string, any>;

  @StorePromoCode.Getter
  private getPromoRequestLoading!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;

  created() {
    this.totalPromoCode = this.campaignDetail.promoCode.length;
    this.totalQuotaCode = GetPromoCodeTotalQuotaAmount(this.campaignDetail.promoCode);

    this.table.header = NewObject(this.getHeaderPromoRequestStatusPromoCodeTable);
    this.table.detail =
      this.campaignDetail.promoCode.length > 0
        ? SetPromoCodeForPromoRequestPromoCodeTable(
            NewObject(this.campaignDetail.promoCode),
            this.campaignDetail.promoCampaign
          )
        : [];
  }

  private BackToTableList() {
    this.$router.push({ name: "promoCodeRequest" });
  }
}
