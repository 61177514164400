import {
  CreatePromoCodeRequestType,
  PackagePeriodType,
  PeakPackage,
  PromoCampaignDiscountType,
  PromoCampaignType,
  PromoCodeCreateLevelType,
  PromoStatus,
  UserRegisterType,
} from "@/enums/enums";
import { BaseTableRequest } from "../table/tableViewModel";

export class PromoCampaignModel {
  id!: number;
  name!: string;
  description!: string;
  reference!: string;
  refOne!: string;
  refTwo!: string;
  isUnique!: boolean;
  isUniqueUser!: boolean;
  isUnpaidUser!: boolean;
  isRecurring!: boolean;
  type!: PromoCampaignType;
  discountNumber!: number;
  discountPercent!: number;
  packageId!: PeakPackage;
  packagePrice!: number;
  packagePeriod!: number;
  packagePeriodDay!: number;
  packageExpiredDate!: Date;
  packagePeriodExtra!: number;
  conditionList?: Record<string, any>[];
}

export class PromoCampaignRequest extends PromoCampaignModel {}

export class PromoCodeListRequestModel extends BaseTableRequest {
  promoCode!: string;
  promoCampaign!: string;
  isExpired!: boolean;
  isFullyUsed!: boolean;
}

export class PromoRequestTableList extends BaseTableRequest {}

export class PromoCodeModel {
  id!: number;
  code!: string;
  campaignId!: number;
  createDateTime!: Date;
  expireDateTime!: Date;
  startDateTime!: Date;
  quota!: number;
  usedQuota!: number;
  fixUserId!: number;
  fixMerchantId!: number;
  fixFirmId!: number;
  fixClientId!: number;
  registerType!: UserRegisterType;
}

export class BasePromoRequest {
  requestId!: string;
  requestNumber!: string;
  status!: PromoStatus;
  requestUser!: string;
}

export class PromoRequestDetail extends BasePromoRequest {
  requestDateTime!: Date;
  editUser!: string;
  editDateTime!: Date | null;
  approveUser!: string;
  approveDateTime!: Date | null;
  voidUser!: string;
  voidDateTime!: Date | null;
  asanaTask!: string;
  reference1!: string;
  fileUrl!: string;
  customerType!: number;
  isCommercial!: boolean;
  referenceQuotation!: PromoReferenceTransactionModel;
  totalAmount!: number;
  referenceInvoice!: PromoReferenceTransactionModel;
}

export class PromoReferenceTransactionModel {
  transactionId!: number;
  transactionCode!: string;
  contactId!: number;
  transactionAmount!: number;
  referenceDateTime!: Date;
}

export class PromoCodeOption {
  campaignId!: number;
  expireDateTime!: Date;
  startDateTime!: Date;
  fixUserId!: number;
  fixMerchantId!: number;
  fixFirmId!: number;
  fixClientId!: number;
}

export class PromoCodeBase extends PromoCodeOption {
  code!: string;
  quota!: number;
}

export class PromoCodeCreateRequest extends PromoCodeBase {}

export class PromoCodeCreateListRequest extends PromoCodeOption {
  amount!: number;
}

export class PromoCodeCreatePatternRequest extends PromoCodeOption {
  amount!: number;
  pattern!: string;
  quota!: number;
}

export class PromoCodeRequestComponentModel {
  code!: string;
  quota!: number;
}

export class PromoCodeRequestModel extends PromoCodeOption {
  code!: Array<PromoCodeRequestComponentModel>;
  status!: PromoStatus;
}

export class PromoCodeReferenceTransactionRequest {
  transactionCode!: string;
}

export class PromoCampaignBase {
  name!: string;
  description!: string;
  reference!: string;
  refOne!: string;
  refTwo!: string;
  isUnique!: boolean;
  isUniqueUser!: boolean;
  isUnpaidUser!: boolean;
  isRecurring!: boolean;
  type!: PromoCampaignType;
  discountNumber!: number;
  discountPercent!: number;
  packageId!: PeakPackage;
  packagePrice!: number;
  packagePeriod!: number;
  packagePeriodDay!: number;
  packageExpiredDate!: Date | null;
  packagePeriodExtra!: number;
}

export class PromoCampaignRequestModel extends PromoCampaignBase {
  campaignId!: number | null;
  status!: PromoStatus;
}

export class PromoRequestElementModel {
  promoCampaign!: PromoCampaignRequestModel;
  promoCode!: Array<PromoCodeRequestModel>;
}

export class PromoRequestBaseModel {
  promoRequest!: Array<PromoRequestElementModel>;
}

export class PromoDraftingRequest extends PromoRequestBaseModel {
  requestNumber!: string;
  status!: PromoStatus;
  asanaTask!: string;
  reference1!: string;
  fileUrl!: string;
  referenceQuotation!: PromoReferenceTransactionModel | null;
  date!: string;
}

export class PromoDraftingEditRequest extends PromoDraftingRequest {
  requestId!: string;
}

export class PromoRequestIndexerBase {
  requestId!: string;
}

export class PromoDraftingIndexRequest extends PromoRequestIndexerBase {}

export class PromoRequestModel extends PromoRequestBaseModel {
  requestUser!: string;
  requestDateTime!: Date;
  editUser!: string;
  editDateTime!: Date | null;
  approveUser!: string;
  approveDateTime!: Date | null;
  voidUser!: string;
  voidDateTime!: Date | null;
  totalAmount!: number;
  referenceInvoice!: PromoReferenceTransactionModel;
}

export class PromoRequestElementListRequest {
  promoRequest!: Array<PromoRequestElementModel>;
}

export class PromoCodeRequest {
  promoCode!: {
    value: string;
    isValidateError: boolean;
  };
  startDate!: {
    value: string;
    isValidateError: boolean;
    modal: boolean;
  };
  expireDate!: {
    value: string;
    isValidateError: boolean;
    modal: boolean;
  };
  quota!: {
    value: any;
    isValidateError: boolean;
  };
  fixUserId!: {
    isCheck: boolean;
    id: {
      value: string;
      isValidateError: boolean;
    };
  };
  fixMerchantId!: {
    isCheck: boolean;
    id: {
      value: string;
      isValidateError: boolean;
    };
  };
  fixFirmId!: {
    isCheck: boolean;
    id: {
      value: string;
      isValidateError: boolean;
    };
  };
  fixClientId!: {
    isCheck: boolean;
    id: {
      value: string;
      isValidateError: boolean;
    };
  };
  registerType!: UserRegisterType;
  amount!: {
    value: number;
    isValidateError: boolean;
  };
  remark!: {
    value: string;
    isValidateError: boolean;
  };
  [key: string]: any;
}

export class PromoCampaignCreateTemplateModel {
  id!: number;
  name!: {
    value: string;
    isValidateError: boolean;
  };
  description!: {
    value: string;
    isValidateError: boolean;
  };
  reference!: {
    value: string;
    isValidateError: boolean;
  };
  refOne!: {
    value: string | number;
    isValidateError: boolean;
  };
  refTwo!: {
    value: string | number;
    isValidateError: boolean;
  };
  isUnique!: boolean;
  isUniqueUser!: boolean;
  isUnpaidUser!: boolean;
  isRecurring!: boolean;
  type!: PromoCampaignType;
  discountNumber!: {
    value: string;
    isValidateError: boolean;
  };
  discountPercent!: {
    value: string;
    isValidateError: boolean;
  };
  packageId!: PeakPackage;
  packagePrice!: {
    value: string;
    isValidateError: boolean;
  };
  packagePeriod!: {
    value: number | string;
    isValidateError: boolean;
    isDisable: boolean;
  };
  packagePeriodDay!: {
    value: number | string;
    isValidateError: boolean;
  };
  packageExpiredDate!: {
    value: null | string;
    isValidateError: boolean;
    isShowModal: boolean;
    [key: string]: any;
  };
  packagePeriodExtra!: {
    value: string;
    isValidateError: boolean;
  };
  periodType!: PackagePeriodType;
  discountType!: PromoCampaignDiscountType;
  [key: string]: any;
}
export type PromoCampaignCreateBySelectBase = {
  value: Partial<PromoCampaignRequest>;
  isValidateError: boolean;
};
export class PromoCodeRequestCreateBySelectModel {
  promoCodeCampaignCreateType!: CreatePromoCodeRequestType;
  campaign!: PromoCampaignCreateBySelectBase;
  promoCodeList!: Array<PromoCodeRequest>;
  promoCodeCreateLevel!: PromoCodeCreateLevelType;
}

export class PromoCodeRequestCreateByCreateModel {
  promoCodeCampaignCreateType!: CreatePromoCodeRequestType;
  campaignRequest!: PromoCampaignCreateTemplateModel;
  promoCodeList!: Array<PromoCodeRequest>;
  promoCodeCreateLevel!: PromoCodeCreateLevelType;
}

export class PromoCodeRequestDetailPromoCampaign {
  status!: PromoStatus;
  campaignId!: number;
  name!: string;
  description!: string;
  reference!: string | null;
  refOne!: string | null;
  refTwo!: string | null;
  isUnique!: boolean;
  isUniqueUser!: boolean;
  isUnpaidUser!: boolean;
  isRecurring!: boolean;
  type!: PromoCampaignType;
  discountNumber!: number;
  discountPercent!: number;
  packageId!: PeakPackage;
  packagePrice!: number;
  packagePeriod!: number;
  packagePeriodDay!: number;
  packageExpiredDate!: string | null;
  packagePeriodExtra!: 0;
}

export class PromoCodeRequestDetailCode {
  status!: PromoStatus;
  isGenerateInvoice!: boolean;
  productCode!: string | null;
  accountCode!: string | null;
  price!: number;
  netAmount!: number;
  code!: string;
  quota!: number;
  campaignId!: number;
  expireDateTime!: string;
  startDateTime!: string;
  fixUserId!: number;
  fixFirmId!: number;
  fixClientId!: number;
  fixMerchantId!: number;
  registerType!: UserRegisterType | null;
  remark!: string;
}

export type PromoCodeRequestCreateElementModel = Pick<
  PromoCodeRequestCreateBySelectModel,
  "promoCodeCampaignCreateType" | "promoCodeList" | "campaign" | "promoCodeCreateLevel"
> &
  Pick<PromoCodeRequestCreateByCreateModel, "campaignRequest">;

export type PromoCodeRequestCreateListModel = Array<PromoCodeRequestCreateElementModel>;

export class PromoCodeRequestRecord {
  promoCampaign!: PromoCodeRequestDetailPromoCampaign & PromoCampaignModel;
  promoCode!: PromoCodeRequestDetailCode[];
}

export class PromoCodeRequestDetailModel {
  editUser!: string;
  editDateTime!: string;
  approveUser!: string;
  approveDateTime!: Date;
  voidUser!: null;
  voidDateTime!: Date | null;
  promoRequest!: PromoCodeRequestRecord[];
  requestNumber!: string;
  asanaTask!: string;
  status!: PromoStatus;
  referenceInvoice!: PromoReferenceTransactionModel;
  reference1!: string;
  referenceQuotation!: PromoReferenceTransactionModel;
  requestUser!: string;
  requestDateTime!: string;
}

export class PromoCodeRequestHeaderDetailBase {
  isError!: boolean;
  value!: string;
}

export class PromoCodeRequestHeaderDetailModel {
  requestNo!: PromoCodeRequestHeaderDetailBase;
  asanaTask!: PromoCodeRequestHeaderDetailBase;
  ref1!: PromoCodeRequestHeaderDetailBase;
  requestDate!: PromoCodeRequestHeaderDetailBase;
}

export class PromoCodeTableDataModel {
  promoCode!: string;
  quota!: number;
  startDate!: string;
  endDate!: string;
  productCode!: string;
  accountCode!: string;
  quotaTotal!: number;
  pricePerQuota!: number;
  remark!: string;
  condition!: string;
  total!: number;
}

export class PromoCodeGenTemplateBase {
  format!: {
    value: string;
    isValidateError: boolean;
  };
  quotaPerCode!: {
    value: string;
    isValidateError: boolean;
  };
  numberOfTimesCreate!: {
    value: string;
    isValidateError: boolean;
  };
}

export type PromoCodeGenTemplate = PromoCodeGenTemplateBase &
  Pick<PromoCodeRequest, "fixUserId" | "fixMerchantId" | "fixFirmId" | "fixClientId" | "startDate" | "expireDate">;
