



























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  GetApprovePromoRequestStatusText,
  GetApprovePromoRequestStatusTextEng,
  SetCampaignConditionList,
  DoMatchAllCampaigns,
} from "@/helper/promoCodeHelper";
import {
  PromoDraftingIndexRequest,
  PromoCodeRequestDetailModel,
  PromoCodeRequestRecord,
  PromoCodeRequestDetailPromoCampaign,
  PromoCampaignModel,
} from "@/model/promoCode/promoCodeModel";
import { NewObject } from "../../../helper/shareHelper";
import PromoCodeRequestStatusDetail from "./PromoCodeRequestStatusDetail.vue";
import { namespace } from "vuex-class";
import { LoadingStatusType, PromoStatus } from "../../../enums/enums";

const StorePromoCode = namespace("PromoCode");

@Component({ components: { PromoCodeRequestStatusDetail }, name: "PromoCodeRequestStatus" })
export default class PromoCodeRequestStatus extends Vue {
  private LoadingStatusType = LoadingStatusType;
  private PromoCodeRequestRecord = PromoCodeRequestRecord;
  private PromoStatus = PromoStatus;
  private requestNo: any = "";
  private asanaTask: any = "";
  private ref1: any = "";
  private requestDate: any = "";
  private modalRequestDate = false;
  private creator: any = "";
  private index = 0;
  private allCampaigns = [];
  private status = PromoStatus.None;
  private statusTextThai = "";
  private statusTextEng = "";
  private requestId = "";
  private isCanApprove = false;
  private isCampaignDetailLoaded = false;

  get statusColor() {
    switch (this.status) {
      case PromoStatus.None:
        return "white";
      case PromoStatus.Draft:
        return "gray";
      case PromoStatus.WaitingApprove:
        return "amber";
      case PromoStatus.Approve:
        return "light-green";
      case PromoStatus.Void:
        return "red";
      default:
        return "white";
    }
  }

  @StorePromoCode.Getter
  private getPromoRequest!: PromoCodeRequestDetailModel;
  @StorePromoCode.Getter
  private getPromoRequestLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getApprovePromoRequestLoading!: LoadingStatusType;
  @StorePromoCode.Getter
  private getPromoCampaignList!: Array<PromoCampaignModel>;
  @StorePromoCode.Getter
  private getPromoCampaignListLoadingStatus!: LoadingStatusType;

  @StorePromoCode.Action
  private DoGetPromoRequest!: (input: PromoDraftingIndexRequest) => void;
  @StorePromoCode.Action
  private DoApprovePromoRequest!: (input: PromoDraftingIndexRequest) => void;
  @StorePromoCode.Action
  private DoGetPromoCampaignList!: () => void;

  get isLoading() {
    return (
      this.getPromoRequestLoading == LoadingStatusType.Loading ||
      this.getApprovePromoRequestLoading == LoadingStatusType.Loading
    );
  }

  get isGetPromoRequestAndGetPromoCampaignListLoadingSuccess() {
    return (
      this.getPromoRequestLoading == LoadingStatusType.Success &&
      this.getPromoCampaignListLoadingStatus == LoadingStatusType.Success
    );
  }

  created() {
    const requestId: any = this.$route.query.requestId ? this.$route.query.requestId : "";
    this.requestId = requestId;
    const input: any = {
      requestId: this.requestId,
    };
    this.DoGetPromoRequest(input);
    this.DoGetPromoCampaignList();
  }

  @Watch("isGetPromoRequestAndGetPromoCampaignListLoadingSuccess")
  getIsGetPromoRequestAndGetPromoCampaignListLoadingSuccessChanged() {
    if (this.isGetPromoRequestAndGetPromoCampaignListLoadingSuccess) {
      this.requestNo = this.getPromoRequest.requestNumber;
      this.asanaTask = this.getPromoRequest.asanaTask ? this.getPromoRequest.asanaTask : "-";
      this.ref1 = this.getPromoRequest.reference1 ? this.getPromoRequest.reference1 : "-";
      this.requestDate = this.getPromoRequest.requestDateTime.GetDateFormat();
      this.creator = this.getPromoRequest.requestUser;
      this.allCampaigns = NewObject(this.getPromoRequest.promoRequest);
      DoMatchAllCampaigns(this.getPromoCampaignList, this.allCampaigns);
      this.status = this.getPromoRequest.status;
      this.statusTextThai = GetApprovePromoRequestStatusText(this.getPromoRequest.status);
      this.statusTextEng = GetApprovePromoRequestStatusTextEng(this.getPromoRequest.status);
      this.isCanApprove = this.getPromoRequest.status == PromoStatus.WaitingApprove;
      this.isCampaignDetailLoaded = true;
    }
  }

  @Watch("getApprovePromoRequestLoading")
  getApprovePromoRequestLoadingChanged() {
    if (this.getApprovePromoRequestLoading == LoadingStatusType.Success) {
      this.$router.push({
        name: "promoCodeRequestDetail",
        query: {
          requestId: this.requestId,
        },
      });
    }
  }

  private ApprovePromoCode() {
    const input = {
      requestId: this.requestId,
    };
    this.DoApprovePromoRequest(input);
  }
}
